export interface IVettingHistoryRow {
  pk: number;
  name: string;
  vetted: boolean;
  timestamp: Date;
};

export interface IUncertainDecimal {
  value: number | "nan" | "inf";
  error: number | "nan" | "inf";
};

export type TFOPPriority = 1|2|3|4|5|null;

export const TFOPMetaData = {
  1: {color: "#096dd9", label: "L1 Critical Candidate"},
  2: {color: "#40a9ff", label: "L1 Candidate"},
  3: {color: "#ffffff", label: "Lower Priority Candidate"},
  4: {color: "#ffffff", label: "On Hold"},
  5: {color: "#ffffff", label: "No More Observations"},
  0: {color: "#ffffff", label: ""},
};

export const TFOPGroupNames = {
  master: "Master",
  sg1a: "SG1a",
  sg1b: "SG1b",
  sg2: "SG2",
  sg3: "SG3",
  sg4: "SG4",
  sg5: "SG5",
};

export interface ITFOP {
  master: TFOPPriority;
  sg1a: TFOPPriority;
  sg1b: TFOPPriority;
  sg2: TFOPPriority;
  sg3: TFOPPriority;
  sg4: TFOPPriority;
  sg5: TFOPPriority;
};

export interface IVettingHistoryResponse extends Array<IVettingHistoryRow>{};

export interface ICollectionOverview {
  pk: number;
  name: string;
};


export interface ICollectionTree {
  pk: number;
  name: string;
  passed_filter: boolean;
  path: Array<number>;
  depth: number;
  children: Array<ICollectionTree>;
};

export interface IFileType {
  pk: number;
  name: string;
  slug: string | null;
  short_name: string | null;
  mime: string | null;
  icon: string | null;
  description: string | null;
};

export interface IFile {
  pk: number;
  created_on: Date;
  sha256_hash: string | null;
  unique_for: string | null;
  file_type: IFileType;
  filename: string;
};

export interface ITOIStar {
  pk: number;
  toi_identifier: number;
};

export interface ITOISignal {
  pk: number;
  toi_star: ITOIStar;
  alerted_on: Date;
  planet_identifier: number;
  tess_name: string | null;
  toi_disposition: string;
  full_toi_id: string;
};

export interface IItemData {
  pk: number;
  slug: number;
  created_on: Date;
  edited_on: Date | null;
  unique_for: ICollectionOverview | null;
  files: Array<IFile>;
  resourcetype: string;
};

export interface IAtlasStar extends IItemData {
  declination: IUncertainDecimal;
  declination_orig: IUncertainDecimal | null;
  effective_temperature: IUncertainDecimal;
  right_ascension: IUncertainDecimal;
  right_ascension_orig: IUncertainDecimal | null;
  stellar_surface_gravity: IUncertainDecimal;
  tmag: IUncertainDecimal;
  toi_star: ITOIStar | null;
  vmag: IUncertainDecimal | null;
};


export interface IAtlasSignal extends IItemData {
  host_star: IAtlasStar;
  snr: number;
  transit_epoch: IUncertainDecimal;
  planet_period: IUncertainDecimal;
  sectors: Array<number>;
  transit_depth: IUncertainDecimal;
  transit_duration: IUncertainDecimal;
  pipeline: string;
  planet_radius: IUncertainDecimal;
  stellar_flux: IUncertainDecimal;
  star_radius: IUncertainDecimal;
  effective_temperature: IUncertainDecimal;
  tfop: ITFOP | null;
  parameters_updated_on: Date;
  centroid_offset: boolean;
  group_disposition: string | null;
  exofop_disposition: string | null;
  detection_pipelines: string | null;
  internal_comment: string | null;
  public_comment: string | null;
  toi_signal: ITOISignal | null;
};

export interface IDeliveredStar extends IItemData {
  atlas: IAtlasStar;
  stellar_parameter_source: string;
  right_ascension: IUncertainDecimal;
  declination: IUncertainDecimal;
  right_ascension_orig: IUncertainDecimal | null;
  declination_orig: IUncertainDecimal | null;
  tmag: IUncertainDecimal;
  effective_temperature: IUncertainDecimal;
};

export interface IDeliveredSignal extends IItemData {
  host_star: IDeliveredStar;
  atlas: IAtlasSignal;
  snr: number;
  transit_epoch: IUncertainDecimal;
  planet_period: IUncertainDecimal;
  sectors: Array<number>;
  transit_depth: IUncertainDecimal;
  transit_duration: IUncertainDecimal;
  pipeline: string;
  planet_radius: IUncertainDecimal;
  stellar_flux: IUncertainDecimal;
  star_radius: IUncertainDecimal;
  effective_temperature: IUncertainDecimal;
  parameters_updated_on: Date;
  centroid_offset: boolean;
  detection_pipelines: string | null;
};

export type PolymorphicItem = IItemData | IAtlasStar | IAtlasSignal | IDeliveredStar | IDeliveredSignal;


export interface IItemRelations {
  siblings: Array<PolymorphicItem>;
  sources: Array<PolymorphicItem>;
  delivery_siblings: Array<PolymorphicItem>;
};

export interface IItemTimeline {
  timestamp: Date;
  type: string;
  message: string;

  color: string | null;
  step: number | null;
};

export const isStar = (item: PolymorphicItem): boolean => {
  return "tmag" in item;
};

export const isSignal = (item: PolymorphicItem): boolean => {
  return "host_star" in item;
};

export const isAtlas = (item: PolymorphicItem): boolean => {
  return !("atlas" in item);
};

export const isDelivery = (item: PolymorphicItem): boolean => {
  return "atlas" in item;
};


// Define Collection interfaces
export interface IItemColumn {
  pk: number;
  name: string;
  file_name_alias: string | null;
  datatype: string;
  description: string | null;
  field_path: string;
  ordering_path: string | null;
};

export interface IColumnList {
  pk: number;
  item_class: string;
  name: string;
  slug: string | null;
  columns: Array<IItemColumn>;
  created_on: Date;
  edited_on: Date | null;
};

export interface ICollectionOverview {
  pk: number;
  name: string;
  description: string | null;
  featured: boolean;
  created_on: Date;
  edited_on: Date | null;
  columns: IColumnList;
};

export interface IAliasedItem {
  [key: string]: any
}

export interface ITableResponse {
  items: Array<IAliasedItem>;
  page: number;
  n_pages: number;
  total_items: number;
};


export type setFileUrlFunc = (arg0: string | null) => void;

// Define Vetting interfaces
export interface IVettingProcessResponse {
  pk: number;
  name: string;
  description: string | null;
  display_columns: IColumnList;
  editable_columns: IColumnList | null;
  vetting_model: string;
  schema: any;
};

export interface VetHistogramEntry {
  [key: number]: number;
};

export interface IVettingProgress {
  n_total_items: number;
  n_vetted_items: number;
  n_vets_by_user: number;
  breakdown: VetHistogramEntry;
};

export interface IVettingProcessOverview {
  pk: number;
  name: string;
  description: string | null;
  vetting_model: string;
};

export interface IVettingResponse {
  pk: number;
  collection_id: number;
  active: boolean;
  process: IVettingProcessResponse;
  progress: IVettingProgress;
};


export interface IVetItemContext {
  slug: number;
  parent_slug: number | null;
  pk: number;
};

export interface IBaseVet {
  pk: number;
  item_id: number;
  vetting_id: number;
  comment: string;
  disposition: string;
  centroid_offset: boolean;
  process: IVettingProcessOverview;
};

export interface IIndividualVet extends IBaseVet {

};

export interface IGroupVet extends IBaseVet {
  tfop: ITFOP;
};

export interface IVetNextItemResponse {
  context: IVetItemContext;
  item: object;
  vet: IIndividualVet | IGroupVet | null;
};
