
import React, {useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography, Popover, Space, Tooltip, Tree, Dropdown, MenuItemProps, MenuProps, Select, SelectProps } from 'antd';
import {IFile, ITFOP, IUncertainDecimal, TFOPGroupNames, TFOPMetaData, TFOPPriority} from '../tevapi/interfaces';
import {IDetailedField} from './detailed-item/field-descriptions';
import {FileContext} from './file-viewer';

export const NumberData: React.FC<{value: number | string}> = ({value}) => {
  const interpreted = Number(value);

  return (
    <Popover
      content={
        <Typography.Paragraph copyable className="decimal" code>
          {interpreted}
        </Typography.Paragraph>
      }
      title="Full Value"
    >
      {interpreted.toFixed(4)}
    </Popover>
  );
};

export const UncertainDecimalData: React.FC<{value: IUncertainDecimal | null}> = ({value}) => {
  if (!value) {
    return null;
  }
  return (
    <Space size="small">
      <NumberData value={value.value} />
      <FontAwesomeIcon className="tev-symbol" icon="fa-solid fa-plus-minus" />
      <NumberData value={value.error} />
    </Space>
  );
};


export const ArrayData: React.FC<{value: Array<any> | null}> = ({value}) => {
  if (!value) {
    return null;
  }
  const strRep = value.join(", ");
  return <StringData value={strRep} cutoff={25} />;
};


export const SubGroup: React.FC<{subgroup: keyof ITFOP, value: TFOPPriority}> = ({subgroup, value}) => {

  const idx = value === null ? 0 : value;

  return (
    <Tooltip title={`${TFOPGroupNames[subgroup]}: ${TFOPMetaData[idx]}`}>
      <Button color={TFOPMetaData[idx].color}>
        {value}
      </Button>
    </Tooltip>
  );
};


export const TFOPData: React.FC<{tfop: ITFOP | null}> = ({tfop}) => {
  if (!tfop) {
    return null;
  }

  return (
    <Space.Compact block>
      <SubGroup value={tfop.master} subgroup="master" />
      <SubGroup value={tfop.sg1a} subgroup="sg1a" />
      <SubGroup value={tfop.sg1b} subgroup="sg1b" />
      <SubGroup value={tfop.sg2} subgroup="sg2" />
      <SubGroup value={tfop.sg3} subgroup="sg3" />
      <SubGroup value={tfop.sg4} subgroup="sg4" />
      <SubGroup value={tfop.sg5} subgroup="sg5" />
    </Space.Compact>
  );
};


const consecutiveSequences = (arr: Array<number>, cutoff: number): Array<string> => {
  const tokens: Array<string> = [];
  let rangeStart = 0;
  let rangeEnd = 1;
  let prevEnd: number | null = null;
  while (rangeEnd < arr.length) {
    prevEnd = rangeEnd - 1;
    if (Math.abs(arr[rangeEnd] - arr[prevEnd]) < 2) {
      rangeEnd++;
    } else {
      if ((prevEnd - rangeStart) < cutoff) {
        for (let i = rangeStart; i <= prevEnd; i++) {
          tokens.push(arr[i].toString());
        }
      } else {
        tokens.push(`${arr[rangeStart]}-${arr[prevEnd]}`);
      }
      rangeStart = rangeEnd;
      rangeEnd++;
    }
  }
  prevEnd = rangeEnd - 1;
  if ((prevEnd - rangeStart) < cutoff && rangeStart < arr.length) {
    for (let i = rangeStart; i <= prevEnd; i++) {
      tokens.push(arr[i].toString());
    }
  } else {
    tokens.push(`${arr[rangeStart]}-${arr[prevEnd]}`);
  }

  return tokens;
}


export const SectorData: React.FC<{sectors: Array<number>}> = ({sectors}) => {
  if (!sectors) {
    return null;
  }
  return <ArrayData value={(consecutiveSequences(sectors, 2))} />;
};


export const StringData: React.FC<{value: string | null, cutoff: number}> = ({value, cutoff}) => {
  if (!value) {
    return null;
  }

  if (value.length > cutoff) {
    return (
      <Typography.Paragraph>
        <Popover
          content={
            <Typography.Paragraph copyable>
              {value}
            </Typography.Paragraph>
          }
        >
          {value.slice(0, cutoff)}...
        </Popover>
      </Typography.Paragraph>
    );
  } else {
    return (
      <Typography.Paragraph>
        {value}
      </Typography.Paragraph>
    );
  }
};

interface FileLinkProps {
  file: IFile,
};

export const FileLink: React.FC<FileLinkProps> = ({file}) => {

  return (
    <Typography.Text>
      {file.unique_for || file.filename}
    </Typography.Text>
  );
};


export const FileArray: React.FC<{files: Array<IFile> | null}> = ({files}) => {
  const groupedFiles: {[key: string]: Array<object>} = {};
  const [fileUrl, setFileUrl] = useContext(FileContext)
  const urls = new Set<string>();

  if (!files) {
    return null;
  }

  files.map((file) => {
    if (file.pk === null) {
      return;
    }
    if (!groupedFiles[file.file_type.name]) {
      groupedFiles[file.file_type.name] = []
    }
    groupedFiles[file.file_type.name].push({
      label: file.unique_for,
      value: `/api/files/${file.pk}/`,
    });
    urls.add(`/api/files/${file.pk}/`);
  });

  const options: SelectProps["options"] = Object.keys(groupedFiles).map(
    (fileType) => ({
      label: fileType,
      key: fileType,
      options: groupedFiles[fileType],
    })
  );
  return (
    <Select
      options={options}
      onSelect={setFileUrl}
      value={urls.has(fileUrl) ? fileUrl : null}
      placeholder="Select File"
      showSearch
      filterOption={(input, option) => {
        return (option?.label ?? "").toString().toLowerCase().includes(input.toLowerCase())
      }}
      dropdownStyle={{
        minWidth: "20em",
      }}
    />
  );
};

interface IFormattedLinks {
  [key: string]: string
};

export const FormattedLinks: React.FC<{links: IFormattedLinks}> = ({links}) => {
  return (
    <Space.Compact block>
      {
        Object.keys(links).map(
          (name) => (
            <Tooltip title={name} key={name}>
              <Button>
                <a href={links[name]} target="none">
                  {name}
                </a>
              </Button>
            </Tooltip>
          )
        )
      }
    </Space.Compact>
  );
};

export const Booleans: React.FC<{value: boolean | null}> = ({value}) => {
  if (value === true) {
    return <FontAwesomeIcon icon="fa-solid fa-check" />;
  } else if (value === false) {
    return  <FontAwesomeIcon icon="fa-solid fa-xmark" />;
  }
  return <Typography.Text>-</Typography.Text>
};

export const DatetimeData: React.FC<{value: string | Date | null}> = ({value}) => {
  if (!value) {
    return null;
  }
  return (
    <StringData value={new Date(value).toString()} cutoff={20}/>
  )
};


export const TEVItemField: React.FC<{value: any, row: IDetailedField}> = ({value, row}) => {
  // Dooop
  switch (row.type) {
    case "string":
      return <StringData value={value} cutoff={20} />;
    case "decimal":
      return <NumberData value={value} />;
    case "number":
      return <NumberData value={value} />;
    case "uncertain_decimal":
      return <UncertainDecimalData value={value} />;
    case "tfop":
      return <TFOPData tfop={value} />;
    case "tfoplist":
      return <TFOPData tfop={value} />;
    case "sectors":
      return <SectorData sectors={value} />;
    case "array":
      return <ArrayData value={value} />;
    case "files":
      return <FileArray files={value} />;
    case "serialized-files":
      return <FileArray files={value} />;
    case "formatted_links":
      return <FormattedLinks links={value} />;
    case "boolean":
      return <Booleans value={value} />;
    case "datetime":
      return <DatetimeData value={value} />
    default:
      return <Typography.Text code>{JSON.stringify(value)}</Typography.Text>
  }
};
