import {Select} from 'antd';
import {map} from 'lodash';
import React from 'react';


export const DISPOSITIONS = {
  PC: "Planet Candidate",
  KP: "Known Planet",
  CP: "Confirmed Planet",
  APC: "Ambiguous Planet Candidate",
  FA: "False Alarm",
  FP: "False Positive",
  IS: "Instrumentation/Systematic",
  EB: "Eclipsing Binary",
  V: "Stellar Variability",
  U: "Undecided",
  O: "Other",
};


export default ({prefix, placeholder}) => {
  return (
    <Select placeholder={placeholder} >
      {map(DISPOSITIONS, (name, disposition) => (
        <Select.Option key={disposition} value={disposition}>
          {prefix}{name}
        </Select.Option>
      ))}
    </Select>
  )
};

export const simpleSelection = ({prefix, placeholder}) => {
  return (
    <Select placeholder={placeholder}>
      {map(DISPOSITIONS, (_, disposition) => (
        <Select.Option key={disposition} value={disposition}>
          {prefix}{disposition}
        </Select.Option>
      ))}
    </Select>
  )
};


export const DispositionSelect = ({options, ...selectProps}) => {
  const possibilities = options.map((key) => {
    return {
      value: key,
      label: DISPOSITIONS[key],
    };
  });

  return (
    <Select
      options={possibilities}
      {...selectProps}
    />
  );
};
