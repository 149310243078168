import {useQuery} from '@tanstack/react-query';
import React, {useContext, useState} from 'react';
import {TEVService} from '../../tevapi/io';
import {Select, Space, Table, Typography} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {VettingContext} from '../../contexts/vetting';
import {useParams} from 'react-router-dom';
import {IGroupVet, IIndividualVet, IVettingHistoryResponse, PolymorphicItem} from '../../tevapi/interfaces';


const transformResponse = (jsonData: IVettingHistoryResponse) => {
  return jsonData.map(
    (history) => ({
        value: history.pk,
        label: (
          <Space>
            {history.name}
            {history.vetted ? <FontAwesomeIcon icon="fa-solid fa-check"/> : null}
          </Space>
        ),
        name: history.name,
    }));
};


export const VettingHistory = () => {
  const vettingContext = useContext(VettingContext);
  const vetting = vettingContext?.vettingQuery.data;

  if (vettingContext === null || vetting === undefined) {
    return null;
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const {data, error, isLoading} = useQuery({
    queryKey: ["vetting", "history", vetting.pk],
    queryFn: async () => {
      if (!vetting.pk) {
        return [];
      }
      const response = await TEVService.get(`/vettings/${vetting.pk}/history`);
      return response.data;
    },
    enabled: dropdownOpen
  });

  if (!vetting) {
    return null;
  }
  const handleDropdownChange = (open: boolean) => {
    setDropdownOpen(open);
  };


  return (
    <Select
      loading={isLoading}
      onDropdownVisibleChange={handleDropdownChange}
      dropdownStyle={{minWidth: "200px"}}
      options={data === undefined ? [] : transformResponse(data)}
      placeholder="Jump to"
      onSelect={(value) => vettingContext.getItem(value)}
      value={null}
      showSearch
      optionFilterProp="name"
    />
  );
};


const VetTable: React.FC<{vets: Array<IIndividualVet | IGroupVet> | undefined}> = ({vets}) => {
   const columns = [
    {
      title: "User",
      dataIndex: "username",
      key: "user",
    },
    {
      title: "Process",
      dataIndex: ["process", "name"],
      key: "process",
    },
    {
      title: "Disposition",
      dataIndex: "disposition",
      key: "disposition",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={vets || []}
      size="small"
      pagination={false}
    />
  );
};


export const VetHistory: React.FC = () => {

  const {pk} = useParams();
  const vettingContext = useContext(VettingContext);

  if (vettingContext === null) {
    return null;
  }
  const {vetContext} = vettingContext;

  const itemPK = vetContext?.context?.pk;

  const query = useQuery({
    queryKey: ["vet", itemPK],
    queryFn: async () => {
      if (!itemPK) {
        return [];
      }
      const response = await TEVService.get(
        `/vets/`,
        {params: {
          process: "Individual Vetting",
          exclude_vetting: pk,
          item: itemPK,
        }},
      );
      return response.data;
    },
  });

  if (query.isLoading || query.data === undefined || query.data.length === 0) {
    return null;
  }


  return (
    <>
      <Typography.Title level={5}>
        User Vets
      </Typography.Title>
      <VetTable
        vets={query.data}
      />
    </>
  );
};

export const ItemVetHistory: React.FC<{item: PolymorphicItem}> = ({item}) => {
  const vetQuery = useQuery({
    queryKey: ["vets", item.pk],
    queryFn: async (): Promise<Array<IIndividualVet | IGroupVet>> => {
      const response = await TEVService.get(
        `/vets/`,
        {params: {item: item.pk}}
      );
      return response.data;
    },
    refetchOnWindowFocus: false,
  });

  return (
    <VetTable 
      vets={vetQuery.data}
    />
  );
};
