import React, {createContext, useContext, useState} from "react";
import {sortBy} from "lodash";

import {TEVService} from "../tevapi/io";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {FileContext} from "../components/file-viewer";
import {useNavigate} from "react-router-dom";
import {message} from "antd";
import {IFile, IVetItemContext, IVetNextItemResponse, IVettingResponse} from "../tevapi/interfaces";
import {AxiosResponse} from "axios";


interface ITransformedItem {
  key: number | string;
  field: string;
  value: any;
  type: string
};

interface IVettingContext {
  vettingQuery: UseQueryResult<IVettingResponse, Error>;
  vetContext: IVetNextItemResponse | null;
  getNextItem: () => void;
  getItem: (pk: number | null) => void;
  submitVet: (formData: object) => void;
  setVetContext: (context: IVetNextItemResponse) => void;
  setFileUrl: (url: string | null) => void;
};


export const VettingContext = createContext<IVettingContext | null>(null);

const transformItem = (item, process) => {
  const horizontal: ITransformedItem[] = [];
  for (const col of process.display_columns.columns) {
    horizontal.push({
      key: col.pk,
      field: col.name,
      value: item[col.name],
      type: col.datatype,
    });
  }
  return horizontal;
}

const sortFiles = (file: IFile) => {
  const name = file.file_type.short_name;
  if (name == "dvr") return 3;
  if (name == "dvs") return 2;
  if (name == "dvm") return 1;
  return 4;
};

const loadFileFromItem = (item, vettingProcess, setFileUrl) => {
  const firstFileColumn = (
    vettingProcess
    .display_columns.columns.find(col => col.datatype === "files")
  );
  if (firstFileColumn === null) {
    setFileUrl(null);
    return;
  }
  const key = firstFileColumn.name;
  const files = sortBy(item[key], sortFiles);
  setFileUrl(`/api/files/${files[0].pk}/`);
};


export const VettingStoreContext = ({pk, ...props}) => {
  const [vetContext, setVetContext] = useState<IVetNextItemResponse | null>(null);
  const [fileUrl, setFileUrl] = useContext(FileContext);

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();

  // Preload Vetting Context
  const vettingQuery = useQuery({
    queryKey: ["vetting"],
    queryFn: async(): Promise<IVettingResponse> => {
      const response = await TEVService.get(`/vettings/${pk}/`);
      return response.data;
    },
    refetchOnWindowFocus: false,
  });

  const interpretNextItemResponse = (response: AxiosResponse<IVetNextItemResponse>): IVetNextItemResponse | null => {

    if (vettingQuery.data === undefined) {
      return null;
    }

    const {context, item, vet} = response.data;
    const transformed = transformItem(
      item,
      vettingQuery.data.process
    );

    // Load default file
    loadFileFromItem(
      item,
      vettingQuery.data.process,
      setFileUrl
    );

    return {
      context: context,
      item: transformed,
      vet: vet,
    };
  };

  const getNextItem = () => {
    TEVService.get(`/vettings/${pk}/next_item/`)
      .then(r => {
        if (r.data.url) {
          navigate(r.data.url);
          return;
        }
        setVetContext(interpretNextItemResponse(r));
        window.scroll(0, 0);
      });
  };

  const getItem = (item_pk: number | null) => {
    TEVService.get(
      `/vettings/${pk}/next_item/`,
      {params: {item_pk}}
    ).then(
        r => {
          if (r.data.url) {
            navigate(r.data.url);
            setFileUrl(null);
          }
          setVetContext(interpretNextItemResponse(r));
        }
    );
  };

  const submitVet = (formData) => {
    const formCopy = {...formData.formData};
    formCopy["item_id"] = vetContext.context.pk;
    TEVService.post(
      `/vettings/${pk}/vet/`,
      formCopy
    ).then(
      (response) => {
        if (response.status == 200) {
          messageApi.open({
            type: "success",
            content: "Submitted Vet!",
          });
          getNextItem();
        } else if (response.status >= 300) {
          messageApi.open({
            type: "error",
            content: JSON.stringify(response)
          });
        }
      }
    )
  };

  const contextObj: IVettingContext = {
    vettingQuery,
    vetContext,
    getNextItem,
    getItem,
    submitVet,
    setVetContext,
    setFileUrl,
  };

  return (
    <VettingContext.Provider value={contextObj}>
      {contextHolder}
      {props.children}
    </VettingContext.Provider>
  );
};
